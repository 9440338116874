import React from 'react';

import { getColor } from 'cccisd-color-picker';
import IconQuestion from 'cccisd-icons/question4';
import Loader from 'cccisd-loader';
import Responsive from 'cccisd-responsive';
import Tooltip from 'cccisd-tooltip';

import useDemographics from '../../hooks/useDemographics.js';
import { getPnfData } from './dataMap';
import style from './style.css';

const GRAPH_HEIGHT_DESKTOP = 300;
const GRAPH_HEIGHT_MOBILE = 175;

const mockData = {
    val1: 2, // Your Number
    val2: 3, // Your Estimate
    val3: 1, // Actual Number
};

const PnfGraph = ({ color1, color2, color3, drinksOrBinge, noDataTooltip, isPreview, pawnId }) => {
    const { isLoading, age, gender, branch, roleHandle, pnfData } = useDemographics({ pawnId });
    const COLOR_MAP = {
        yourNumber: getColor(color1),
        yourEstimate: getColor(color2),
        actualNumber: getColor(color3),
    };

    if (isLoading) {
        return <Loader loading type="inline" />;
    }

    let data = mockData;
    if (!isPreview) {
        if (roleHandle === 'learner') {
            data.val1 = parseInt(
                drinksOrBinge === 'drinks' ? pnfData.cp_drinks_per_day : pnfData.cp_days_binge_drinking,
                10
            );
            data.val2 = parseInt(
                drinksOrBinge === 'drinks'
                    ? pnfData.cp_drinks_per_day_estimation
                    : pnfData.cp_days_binge_drinking_estimation,
                10
            );
            data.val3 = getPnfData(true, age, gender, branch);
        }

        if (roleHandle === 'sm') {
            data.val1 = parseInt(
                drinksOrBinge === 'drinks' ? pnfData.sm_drinks_per_day : pnfData.sm_days_binge_drinking,
                10
            );
            data.val2 = parseInt(
                drinksOrBinge === 'drinks'
                    ? pnfData.sm_drinks_per_day_estimation
                    : pnfData.sm_days_binge_drinking_estimation,
                10
            );
            data.val3 = getPnfData(false, age, gender, branch);
        }
    }

    const { val1, val2, val3 } = data;
    let maxValue = val1;
    if (val2 > maxValue) {
        maxValue = val2;
    }
    if (val3 > maxValue) {
        maxValue = val3;
    }

    return (
        <Responsive
            stylesheet={style}
            render={({ width }) => {
                const scale = (width < 768 ? GRAPH_HEIGHT_MOBILE : GRAPH_HEIGHT_DESKTOP) / maxValue;
                return (
                    <div className={style.graph}>
                        <div style={{ height: scale * val1, backgroundColor: COLOR_MAP.yourNumber }}>
                            <span className={style.topLabel}>{val1 === -1 ? 'n/a' : val1}</span>
                            <span className={style.bottomLabel} style={{ borderColor: COLOR_MAP.yourNumber }}>
                                Your number
                            </span>
                        </div>
                        <div style={{ height: scale * val2, backgroundColor: COLOR_MAP.yourEstimate }}>
                            <span className={style.topLabel}>{val2 === -1 ? 'n/a' : val2}</span>
                            <span className={style.bottomLabel} style={{ borderColor: COLOR_MAP.yourEstimate }}>
                                Your estimate
                            </span>
                        </div>
                        <div style={{ height: scale * val3, backgroundColor: COLOR_MAP.actualNumber }}>
                            <span className={style.topLabel}>{val3 === -1 ? 'n/a' : val3}</span>
                            <span className={style.bottomLabel} style={{ borderColor: COLOR_MAP.actualNumber }}>
                                {val3 === -1 && (
                                    <Tooltip title={noDataTooltip}>
                                        <IconQuestion spaceRight />
                                    </Tooltip>
                                )}
                                Actual number
                            </span>
                        </div>
                    </div>
                );
            }}
        />
    );
};

export default PnfGraph;
