import React from 'react';
import IconBar from 'cccisd-icons/stats-bars5';

export const fields = ['color1', 'color2', 'color3', 'drinksOrBinge', 'noDataTooltip'];

export const initialValues = {
    color1: '#02ACD9',
    color2: '#5A5DA8',
    color3: '#F58027',
    drinksOrBinge: 'drinks',
    noDataTooltip: 'Not enough data available for your specific age group and gender.',
};

export const icon = <IconBar />;

export const getDataDefinitions = {};

export const validate = values => {
    return {};
};
