import React from 'react';

import Loader from 'cccisd-loader';

import { getValues } from './getValues.js';
import { replaceableVariables } from '../Builder/settings.js';
import useDemographics from '../../../../../hooks/useDemographics.js';

function HRBSRender(props) {
    const {
        isPreview,
        pawnId,
        settings: { statement, stylesJson },
    } = props;

    let styles;
    try {
        styles = JSON.parse(stylesJson);
    } catch (e) {
        styles = {};
    }

    if (isPreview) {
        return <p style={styles}>HRBS Statement</p>;
    }

    const { isLoading, age, gender, branch, roleHandle } = useDemographics({ pawnId });
    if (isLoading) {
        return <Loader loading type="inline" />;
    }

    // if previewing in survey builder, pawnId is 0
    if (pawnId !== 0 && roleHandle !== 'sm') {
        return <p style={styles}>HRBS Data not available for this role.</p>;
    }

    let base = statement;

    // hardcode a message if previewing in survey builder
    const replacements = pawnId ? getValues(gender, age, branch) : getValues(1, 25, 'army');

    if (replacements === -1 || typeof replacements !== 'object') {
        console.error('No HRBS data available for the given gender/age/branch combination');
        return <span />;
    }

    replaceableVariables.forEach(v => {
        if (!replacements[v]) {
            return;
        }

        const reg = new RegExp(v, 'g');
        base = base.replace(reg, replacements[v]);
    });

    return <p style={styles}>{base}</p>;
}

export default HRBSRender;
