import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Header from 'cccisd-header';
import IconMail from 'cccisd-icons/envelop2';
import style from './style.css';
import { CPBaselineContext } from '../AppContext';

const Appdefs = window.cccisd.appDefs;
const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

const NavBar = ({ className, noLogoLink }) => {
    const { isBaselineComplete } = useContext(CPBaselineContext);
    const isInviteSmLink = Fortress.user.acting.role.handle === 'learner' && isBaselineComplete;

    let logoLink = '/';
    try {
        const homepageHandle = Appdefs.navs.find(n => n.homepage).homepage;
        logoLink = Appdefs.routes.find(r => r.handle === homepageHandle).url;
    } catch (e) {
        // meh welcome page is fine
    }

    let primaryNavLinks = [];
    try {
        primaryNavLinks = Appdefs.navs.find(n => n.handle === 'primaryNav').nav;
    } catch (e) {
        // meh, leave it empty
    }

    function goToInviteSm(e) {
        e.preventDefault();
        window.open(Boilerplate.url('/survey/invitesm'));
    }

    // completing baseline will Add To DCW and force a page refresh,
    // so no need to manually call it onComplete of deployment
    if (isInviteSmLink) {
        primaryNavLinks = [
            ...primaryNavLinks,
            {
                handle: 'inviteSm',
                url: '#',
                label: <span onClick={goToInviteSm}>Invite Service Member</span>,
            },
            {
                handle: 'resources',
                url: '#',
                label: (
                    <span
                        onClick={e => {
                            e.preventDefault();
                            window.open(Boilerplate.url('/cp/resources'));
                        }}
                    >
                        Resources
                    </span>
                ),
            },
        ];
    }

    return (
        <div>
            <Header
                containerClassName={`${className} ${style.mobile} ${isInviteSmLink ? style.inviteSmGreen : ''}`}
                logoClassName={style.logoPlaceholder}
                primaryNavLinks={primaryNavLinks}
                showAlertAction={false}
                showHomeAction={false}
                // would normally refer to noLogoLink, but we want users
                // to be able to navigate around using the Deployment header
                logoLink={logoLink}
            />
            {isInviteSmLink && (
                <div className={style.mobileInviteSmLink}>
                    <button className="btn btn-link" onClick={goToInviteSm} type="button">
                        <IconMail spaceRight />
                        Invite Service Member
                    </button>
                </div>
            )}
        </div>
    );
};

NavBar.defaultProps = {
    className: 'container',
    noLogoLink: false,
};

NavBar.propTypes = {
    className: PropTypes.string,
    noLogoLink: PropTypes.bool,
};

export default NavBar;
