import React, { useState, createContext, useEffect } from 'react';
import { client } from 'cccisd-apollo';
import query from './query.graphql';

const Fortress = window.cccisd.fortress;
export const CPBaselineContext = createContext({
    isBaselineComplete: null,
    isStage2RedirectComplete: null,
    stage2DeploymentHash: null,
});

const AppContext = ({ children }) => {
    const [isBaselineComplete, setIsBaselineComplete] = useState(null);
    const [isStage2RedirectComplete, seIsStage2RedirectComplete] = useState(null);
    const [stage2DeploymentHash, setStage2DeploymentHash] = useState(null);

    async function checkIsComplete() {
        if (Fortress.user.acting.role.handle !== 'learner') {
            setIsBaselineComplete(true);
            seIsStage2RedirectComplete(true);
            return;
        }

        const resp = await client.query({
            query,
            fetchPolicy: 'network-only',
        });

        setIsBaselineComplete(!!resp?.data?.flows?.baseline?.completed);
        const isStage2 = !!resp?.data?.flows?.stage2Randomizer?.completed;
        if (isStage2) {
            setStage2DeploymentHash(resp?.data?.flows?.stage2Randomizer?.deployment?.hash);
        } else {
            seIsStage2RedirectComplete(false);
        }
    }

    useEffect(() => {
        if (stage2DeploymentHash) {
            seIsStage2RedirectComplete(true);
        }
    }, [stage2DeploymentHash]);

    useEffect(() => {
        if (!isBaselineComplete || !isStage2RedirectComplete) {
            checkIsComplete();
        }
    }, [isBaselineComplete, isStage2RedirectComplete]);

    return (
        <CPBaselineContext.Provider value={{ isBaselineComplete, isStage2RedirectComplete, stage2DeploymentHash }}>
            {children}
        </CPBaselineContext.Provider>
    );
};

export default AppContext;
