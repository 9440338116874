import React, { useContext } from 'react';
import { Redirect } from 'cccisd-react-router';
import Loader from 'cccisd-loader';
import { CPBaselineContext } from '../../components/AppContext';

const Fortress = window.cccisd.fortress;

const CPRedirector = () => {
    const { isBaselineComplete, isStage2RedirectComplete, stage2DeploymentHash } = useContext(CPBaselineContext);

    if (Fortress.user.acting.role.handle !== 'learner') {
        return <Redirect to="/" />;
    }

    // Means graphql query has not finished yet
    if (isBaselineComplete === null) {
        return <Loader loading />;
    }

    if (isBaselineComplete === false) {
        return <Redirect to="/survey/cpbaseline" />;
    }

    if (isStage2RedirectComplete === false) {
        return <Redirect to="/survey/cpbaseline" />;
    }

    // wait for both bits of state to be set before performing redirect
    if (!(stage2DeploymentHash && isStage2RedirectComplete)) {
        return <Loader loading />;
    }

    return <Redirect to={`/d/${stage2DeploymentHash}/${Fortress.user.acting.respondent_hash}`} />;
};

export default CPRedirector;
