import React from 'react';
import axios from 'cccisd-axios';
import notification from 'cccisd-notification';
import { RegisterLoginPage } from 'cccisd-laravel-nexus';

const Boilerplate = window.cccisd.boilerplate;

const SMRegister = () => {
    const registerLoginProps = {
        registerProps: {
            onRegisterSubmit: async vals => {
                const resp = await axios.post(Boilerplate.route('api.app.registerServiceMember'), vals);
                if (resp.data.status === 'error') {
                    throw resp.data.errors;
                } else if (resp.data.status !== 'success') {
                    notification({ message: 'Failed to register user', type: 'danger' });
                    return;
                }

                const loginResp = await axios.post(Boilerplate.route('api.nexus.login'), {
                    ...vals,
                    username: vals.email,
                });

                if (loginResp.data.status !== 'success') {
                    notification({
                        message: 'Successfully registered, but login attempt failed. Try logging in again.',
                        type: 'danger',
                    });
                    return;
                }

                // full page refresh so login takes effect
                window.location = Boilerplate.url('/survey/smbaseline');
            },
            showPasswordFields: true,
        },
    };

    return <RegisterLoginPage {...registerLoginProps} />;
};

export default SMRegister;
