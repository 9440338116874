/**
 * Illustrates line wrapping for long param/return descriptions.
 *
 * @param {boolean} isCp `true` if Concerned Partner; `false` if Service Member
 * @param {number}  age age of person in question based on devTag
 * @param {string}  gender either "M" or "F" ("F" is anything that is not male)
 * @param {string}  branch branch of military of person in question based on devTag
 *
 * @return {number} average drinks per day for given population
 */

export function getPnfData(isCp, age, gender, branch) {
    switch (isCp) {
        case true:
            return getCpData(age, gender);
        case false:
            return getSmData(age, gender, branch);
        default:
            console.error('Could not determine whether user is concerned partner or service member');
            return 0;
    }
}

function getCpData(age, gender) {
    switch (true) {
        case age <= 23:
            return gender === 1 ? 2 : 1;
        case age <= 25:
            return gender === 1 ? 2 : 2;
        case age <= 29:
            return gender === 1 ? 2 : 1;
        case age <= 34:
            return gender === 1 ? 2 : 1;
        case age <= 49:
            return gender === 1 ? 2 : 1;
        case age <= 64:
            return gender === 1 ? 2 : 1;
        default:
            return -1;
    }
}

function getSmData(age, gender, branch) {
    // We might add "Space Force" later, but there's no data for that branch
    if (!['airForce', 'army', 'coastGuard', 'marines', 'navy'].includes(branch)) {
        return -1;
    }

    switch (true) {
        case age <= 23:
            if (branch === 'airForce') {
                return gender === 1 ? 3 : 2;
            }
            if (branch === 'army') {
                return gender === 1 ? 5 : 2;
            }
            if (branch === 'coastGuard') {
                return gender === 1 ? 3 : 3;
            }
            if (branch === 'marines') {
                return gender === 1 ? 5 : 4;
            }
            if (branch === 'navy') {
                return gender === 1 ? 5 : 3;
            }
        case age <= 25:
            if (branch === 'airForce') {
                return gender === 1 ? 3 : 2;
            }
            if (branch === 'army') {
                return gender === 1 ? 3 : 2;
            }
            if (branch === 'coastGuard') {
                return gender === 1 ? 3 : 2;
            }
            if (branch === 'marines') {
                return gender === 1 ? 3 : 3;
            }
            if (branch === 'navy') {
                return gender === 1 ? 4 : 2;
            }
        case age <= 29:
            if (branch === 'airForce') {
                return gender === 1 ? 3 : 2;
            }
            if (branch === 'army') {
                return gender === 1 ? 3 : 3;
            }
            if (branch === 'coastGuard') {
                return gender === 1 ? 3 : 2;
            }
            if (branch === 'marines') {
                return gender === 1 ? 4 : 3;
            }
            if (branch === 'navy') {
                return gender === 1 ? 3 : 2;
            }
        case age <= 34:
            if (branch === 'airForce') {
                return gender === 1 ? 2 : 2;
            }
            if (branch === 'army') {
                return gender === 1 ? 3 : 2;
            }
            if (branch === 'coastGuard') {
                return gender === 1 ? 3 : 2;
            }
            if (branch === 'marines') {
                return gender === 1 ? 3 : 2;
            }
            if (branch === 'navy') {
                return gender === 1 ? 3 : 2;
            }
        case age <= 49:
            if (branch === 'airForce') {
                return gender === 1 ? 2 : 2;
            }
            if (branch === 'army') {
                return gender === 1 ? 2 : 2;
            }
            if (branch === 'coastGuard') {
                return gender === 1 ? 2 : 2;
            }
            if (branch === 'marines') {
                return gender === 1 ? 3 : 2;
            }
            if (branch === 'navy') {
                return gender === 1 ? 3 : 2;
            }
        case age <= 64:
            if (branch === 'airForce') {
                return gender === 1 ? 2 : -1;
            }
            if (branch === 'army') {
                return gender === 1 ? 2 : 2;
            }
            if (branch === 'coastGuard') {
                return -1;
            }
            if (branch === 'marines') {
                return gender === 1 ? 2 : -1;
            }
            if (branch === 'navy') {
                return gender === 1 ? 2 : 2;
            }
        default:
            return -1;
    }
}
