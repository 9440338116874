import builder from './Builder';
import { icon, initialValues as builderInitialValues, getDataDefinitions } from './Builder/settings.js';
import player from './Player';

export default {
    code: 'hrbs',
    name: 'HRBS Statement',
    labelField: 'text',
    icon,
    builder,
    builderInitialValues,
    player,
    getDataDefinitions,
};
