import React from 'react';
import IconFile from 'cccisd-icons/file-text';

export const replaceableVariables = [
    '%AGE_GROUP%',
    '%DRINK_NUMBER%',
    '%GENDER_SINGULAR%',
    '%GENDER_PLURAL%',
    '%MILITARY_BRANCH%',
    '%PERCENT_1%',
    '%PERCENT_2%',
];

export const fields = ['statement', 'stylesJson'];

export const initialValues = {
    statement:
        '%PERCENT_1% of %GENDER_PLURAL% aged %AGE_GROUP% serving in the %MILITARY_BRANCH% report not drinking at all in the past month. Among those who do drink alcohol, %PERCENT_2% have %DRINK_NUMBER% drinks or less when they drink.',
    stylesJson: `{
    "padding": "4px 8px",
    "margin": "0px",
    "backgroundColor": "rgba(255, 255, 255, 0.9)",
    "borderRadius": "5px"
}`,
};

export const icon = <IconFile />;

export const getDataDefinitions = {};

export const validate = values => {
    const errs = {};
    if (!values.statement) {
        errs.statement = 'Required';
    }

    let isJsonErr = false;
    try {
        JSON.parse(values.stylesJson);
    } catch (e) {
        isJsonErr = true;
    }

    if (isJsonErr) {
        errs.stylesJson = 'Invalid JSON format';
    }

    return errs;
};
