import React from 'react';
import PropTypes from 'prop-types';
import { builderWrapper, FieldBlock } from 'cccisd-survey-common';
import { ColorPicker } from 'cccisd-redux-form-helpers';
import { fields, validate, initialValues } from './settings.js';

export class PNFBuilder extends React.Component {
    static propTypes = {
        fields: PropTypes.object,
    };

    render() {
        const { color1, color2, color3, drinksOrBinge, noDataTooltip } = this.props.fields;

        return (
            <div>
                <FieldBlock field={color1} label="Color 1">
                    <ColorPicker field={color1} />
                </FieldBlock>
                <FieldBlock field={color2} label="Color 2">
                    <ColorPicker field={color2} />
                </FieldBlock>
                <FieldBlock field={color3} label="Color 3">
                    <ColorPicker field={color3} />
                </FieldBlock>
                <FieldBlock field={drinksOrBinge} label="PNF Metric">
                    <select
                        name="drinksOrBinge"
                        value={drinksOrBinge.value}
                        onChange={drinksOrBinge.onChange}
                        className="form-control"
                    >
                        <option value="drinks">Drinks per day</option>
                        <option value="binges">Binges per 30 days</option>
                    </select>
                </FieldBlock>
                <FieldBlock field={noDataTooltip} label="No Data Tooltip">
                    <div className="form-group">
                        <input
                            className="form-control"
                            id="noDataTooltip"
                            name="noDataTooltip"
                            value={noDataTooltip.value}
                            onChange={noDataTooltip.onChange}
                        />
                    </div>
                </FieldBlock>
            </div>
        );
    }
}

export default builderWrapper({
    fields,
    validate,
    initialValues,
    withTemplate: false,
    withAccommodations: false,
})(PNFBuilder);
