import React, { useContext } from 'react';
import style from './style.css';
import { CPBaselineContext } from '../../components/AppContext';
const Fortress = window.cccisd.fortress;

const QuestLayout = props => {
    const { isBaselineComplete } = useContext(CPBaselineContext);

    // "Invite Service Member" link shows up after baseline is complete
    // We use NavBar component to show it, but we need to add extra padding here in QuestLayout
    return (
        <div
            className={isBaselineComplete && Fortress.user.acting.role.handle === 'learner' ? style.addPaddingTop : ''}
        >
            {props.children}
        </div>
    );
};
export default QuestLayout;
