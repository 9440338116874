import { useState, useEffect } from 'react';
import { client } from 'cccisd-apollo';
import _get from 'lodash/get';

import pawnDetailsQuery from './pawnDetails.graphql';
import devTagsCpQuery from './devTags_cp.graphql';
import devTagsSmQuery from './devTags_sm.graphql';

function useDemographics(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [age, setAge] = useState(null);
    const [gender, setGender] = useState(null);
    const [genderRaw, setGenderRaw] = useState(null);
    const [branch, setBranch] = useState(null);
    const [pnfData, setPnfData] = useState({});
    const [cpAnxietyScore, setCpAnxietyScore] = useState(null);
    const [cpDepressionScore, setCpDepressionScore] = useState(null);
    const [roleHandle, setRoleHandle] = useState('');

    useEffect(() => {
        async function getDemographics() {
            const { pawnId } = props;

            // can't rely on Fortress.user.acting because
            // we might be taking it via unique respondent link
            const pawnDetails = await client.query({
                query: pawnDetailsQuery,
                variables: { pawnId },
                fetchPolicy: 'network-only',
            });

            let role = '';
            let cpPawnId = -1;
            try {
                const pawnInfo = pawnDetails.data.roles.anyRole;
                role = pawnInfo.role.handle;
                if (role === 'sm') {
                    cpPawnId = pawnInfo.parentRoles.learner.pawn.pawnId;
                }
            } catch (e) {
                console.error('Could not determine role of current pawn');
            }

            if (!['learner', 'sm'].includes(role)) {
                setIsLoading(false);
                return;
            }

            setRoleHandle(role);
            switch (role) {
                // Concerned Partners
                case 'learner': {
                    const cpResp = await client.query({
                        query: devTagsCpQuery,
                        variables: { pawnId },
                        fetchPolicy: 'network-only',
                    });

                    const cpBase = cpResp.data.flows;
                    const demographicDevTags = _get(cpBase, 'cp_baseline.devTags', {}) || {};

                    const cpAge = parseInt(demographicDevTags.cp_age, 10);
                    const cpGender = parseInt(demographicDevTags.cp_sex, 10);
                    const cpGenderRaw = parseInt(demographicDevTags.cp_gender_raw, 10);
                    const smBranch = demographicDevTags.sm_branch;

                    const anxietyScore = parseInt(demographicDevTags.cp_anxiety_score, 10);
                    const depressionScore = parseInt(demographicDevTags.cp_depression_score, 10);

                    setAge(Number.isNaN(cpAge) ? -1 : cpAge);
                    setGender(Number.isNaN(cpGender) ? -1 : cpGender);
                    setGenderRaw(cpGenderRaw);
                    setBranch(smBranch);
                    setPnfData(_get(cpBase, 'cp_intervention.devTags', {}) || {});
                    setCpAnxietyScore(anxietyScore);
                    setCpDepressionScore(depressionScore);
                    setIsLoading(false);
                    break;
                }
                case 'sm': {
                    const smResp = await client.query({
                        query: devTagsSmQuery,
                        variables: { pawnId, cpPawnId },
                        fetchPolicy: 'network-only',
                    });

                    const smBase = smResp.data.flows;

                    let smAge = parseInt(_get(smBase, 'sm_baseline.devTags.sm_age'), 10);
                    let smGender = parseInt(_get(smBase, 'sm_baseline.devTags.sm_sex'), 10);
                    let smGenderRaw = parseInt(_get(smBase, 'sm_baseline.devTags.sm_gender_raw'), 10);
                    let branchDevTag = _get(smBase, 'sm_baseline.devTags.sm_branch');

                    // both SM and CP answer age/sex/branch, so try to parse
                    // from CP baseline if not valid in SM baseline
                    if (Number.isNaN(smAge)) {
                        smAge = parseInt(_get(smBase, 'cp_baseline.sm_age'), 10);
                    }
                    if (Number.isNaN(smGender)) {
                        smGender = parseInt(_get(smBase, 'cp_baseline.sm_sex'), 10);
                    }
                    if (Number.isNaN(smGenderRaw)) {
                        smGenderRaw = parseInt(_get(smBase, 'cp_baseline.sm_gender_raw'), 10);
                    }
                    if (!branchDevTag) {
                        branchDevTag = _get(smBase, 'cp_baseline.devTags.sm_branch');
                    }

                    const anxietyScore = parseInt(_get(smBase, 'cp_baseline.devTags.cp_anxiety_score'), 10);
                    const depressionScore = parseInt(_get(smBase, 'cp_baseline.devTags.cp_depression_score'), 10);

                    setAge(Number.isNaN(smAge) ? -1 : smAge);
                    setGender(Number.isNaN(smGender) ? -1 : smGender);
                    setGenderRaw(smGenderRaw);
                    setBranch(branchDevTag);
                    setPnfData(_get(smBase, 'sm_intervention.devTags', {}) || {});
                    setCpAnxietyScore(anxietyScore);
                    setCpDepressionScore(depressionScore);
                    setIsLoading(false);
                    break;
                }
                default:
                    setIsLoading(false);
            }
        }

        if (!props.pawnId) {
            setIsLoading(false);
            return;
        }

        if (!(age && gender && branch)) {
            getDemographics();
        }
    }, [age, gender, branch, props.pawnId]);

    return { isLoading, age, gender, genderRaw, branch, roleHandle, pnfData, cpAnxietyScore, cpDepressionScore };
}

export default useDemographics;
