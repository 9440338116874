export function getValues(gender, age, branch) {
    return gender === 1 ? getMaleValues(age, branch) : getNonMaleValues(gender, age, branch);
}

const branchMapper = {
    airForce: 'Air Force',
    army: 'Army',
    coastGuard: 'Coast Guard',
    marines: 'Marines',
    navy: 'Navy',
};

function getMaleValues(age, branch) {
    // We might add "Space Force" later, but there's no data for that branch
    if (!['airForce', 'army', 'coastGuard', 'marines', 'navy'].includes(branch)) {
        return -1;
    }

    const vals = {
        '%GENDER_PLURAL%': 'men',
        '%GENDER_SINGULAR%': 'man',
        '%DRINK_NUMBER%': 3,
        '%MILITARY_BRANCH%': branchMapper[branch],
    };

    switch (true) {
        case age <= 23:
            vals['%AGE_GROUP%'] = '18-23';
            if (branch === 'airForce') {
                vals['%PERCENT_1%'] = '46%';
                vals['%PERCENT_2%'] = '75%';
            }

            if (branch === 'army') {
                vals['%PERCENT_1%'] = '45%';
                vals['%PERCENT_2%'] = '64%';
            }

            if (branch === 'coastGuard') {
                vals['%PERCENT_1%'] = '43%';
                vals['%PERCENT_2%'] = '72%';
            }

            if (branch === 'marines') {
                vals['%PERCENT_1%'] = '34%';
                vals['%PERCENT_2%'] = '62%';
            }

            if (branch === 'navy') {
                vals['%PERCENT_1%'] = '31%';
                vals['%PERCENT_2%'] = '61%';
            }

            break;
        case age <= 25:
            vals['%AGE_GROUP%'] = '24-25';
            if (branch === 'airForce') {
                vals['%PERCENT_1%'] = '25%';
                vals['%PERCENT_2%'] = '84%';
            }

            if (branch === 'army') {
                vals['%PERCENT_1%'] = '29%';
                vals['%PERCENT_2%'] = '70%';
            }

            if (branch === 'coastGuard') {
                vals['%PERCENT_1%'] = '13%';
                vals['%PERCENT_2%'] = '68%';
            }

            if (branch === 'marines') {
                vals['%PERCENT_1%'] = '16%';
                vals['%PERCENT_2%'] = '66%';
            }

            if (branch === 'navy') {
                vals['%PERCENT_1%'] = '18%';
                vals['%PERCENT_2%'] = '65%';
            }

            break;
        case age <= 29:
            vals['%AGE_GROUP%'] = '26-29';
            if (branch === 'airForce') {
                vals['%PERCENT_1%'] = '25%';
                vals['%PERCENT_2%'] = '86%';
            }

            if (branch === 'army') {
                vals['%PERCENT_1%'] = '30%';
                vals['%PERCENT_2%'] = '73%';
            }

            if (branch === 'coastGuard') {
                vals['%PERCENT_1%'] = '18%';
                vals['%PERCENT_2%'] = '81%';
            }

            if (branch === 'marines') {
                vals['%PERCENT_1%'] = '18%';
                vals['%PERCENT_2%'] = '72%';
            }

            if (branch === 'navy') {
                vals['%PERCENT_1%'] = '18%';
                vals['%PERCENT_2%'] = '74%';
            }

            break;
        case age <= 34:
            vals['%AGE_GROUP%'] = '30-34';
            if (branch === 'airForce') {
                vals['%PERCENT_1%'] = '23%';
                vals['%PERCENT_2%'] = '87%';
            }

            if (branch === 'army') {
                vals['%PERCENT_1%'] = '26%';
                vals['%PERCENT_2%'] = '82%';
            }

            if (branch === 'coastGuard') {
                vals['%PERCENT_1%'] = '24%';
                vals['%PERCENT_2%'] = '84%';
            }

            if (branch === 'marines') {
                vals['%PERCENT_1%'] = '19%';
                vals['%PERCENT_2%'] = '76%';
            }

            if (branch === 'navy') {
                vals['%PERCENT_1%'] = '15%';
                vals['%PERCENT_2%'] = '75%';
            }

            break;
        case age <= 49:
            vals['%AGE_GROUP%'] = '35-49';
            if (branch === 'airForce') {
                vals['%PERCENT_1%'] = '24%';
                vals['%PERCENT_2%'] = '90%';
            }

            if (branch === 'army') {
                vals['%PERCENT_1%'] = '26%';
                vals['%PERCENT_2%'] = '85%';
            }

            if (branch === 'coastGuard') {
                vals['%AGE_GROUP%'] = '35-64';
                vals['%PERCENT_1%'] = '16%';
                vals['%PERCENT_2%'] = '86%';
            }

            if (branch === 'marines') {
                vals['%PERCENT_1%'] = '18%';
                vals['%PERCENT_2%'] = '77%';
            }

            if (branch === 'navy') {
                vals['%PERCENT_1%'] = '20%';
                vals['%PERCENT_2%'] = '82%';
            }

            break;
        case age <= 64:
            vals['%AGE_GROUP%'] = '50-64';
            if (branch === 'airForce') {
                vals['%PERCENT_1%'] = '23%';
                vals['%PERCENT_2%'] = '84%';
            }

            if (branch === 'army') {
                vals['%PERCENT_1%'] = '27%';
                vals['%PERCENT_2%'] = '84%';
            }

            if (branch === 'coastGuard') {
                vals['%AGE_GROUP%'] = '35-64';
                vals['%PERCENT_1%'] = '16%';
                vals['%PERCENT_2%'] = '86%';
            }

            if (branch === 'marines') {
                vals['%PERCENT_1%'] = '15%';
                vals['%PERCENT_2%'] = '78%';
            }

            if (branch === 'navy') {
                vals['%PERCENT_1%'] = '25%';
                vals['%PERCENT_2%'] = '94%';
            }

            break;
        default:
            return -1;
    }

    return vals;
}

function getNonMaleValues(gender, age, branch) {
    // We might add "Space Force" later, but there's no data for that branch
    if (!['airForce', 'army', 'coastGuard', 'marines', 'navy'].includes(branch)) {
        return -1;
    }

    const vals = {
        '%GENDER_PLURAL%': gender === 2 ? 'women' : 'women and nonbinary people',
        '%GENDER_SINGULAR%': gender === 2 ? 'woman' : 'woman or nonbinary person',
        '%DRINK_NUMBER%': 3,
        '%MILITARY_BRANCH%': branchMapper[branch],
    };

    switch (true) {
        case age <= 23:
            vals['%AGE_GROUP%'] = '18-23';
            if (branch === 'airForce') {
                vals['%PERCENT_1%'] = '40%';
                vals['%PERCENT_2%'] = '86%';
            }

            if (branch === 'army') {
                vals['%PERCENT_1%'] = '59%';
                vals['%PERCENT_2%'] = '82%';
            }

            if (branch === 'coastGuard') {
                vals['%PERCENT_1%'] = '39%';
                vals['%PERCENT_2%'] = '83%';
            }

            if (branch === 'marines') {
                vals['%PERCENT_1%'] = '44%';
                vals['%PERCENT_2%'] = '65%';
            }

            if (branch === 'navy') {
                vals['%PERCENT_1%'] = '38%';
                vals['%PERCENT_2%'] = '69%';
            }

            break;
        case age <= 25:
            vals['%AGE_GROUP%'] = '24-25';
            if (branch === 'airForce') {
                vals['%PERCENT_1%'] = '24%';
                vals['%PERCENT_2%'] = '85%';
            }

            if (branch === 'army') {
                vals['%PERCENT_1%'] = '40%';
                vals['%PERCENT_2%'] = '61%';
            }

            if (branch === 'coastGuard') {
                vals['%PERCENT_1%'] = '15%';
                vals['%PERCENT_2%'] = '84%';
            }

            if (branch === 'marines') {
                vals['%PERCENT_1%'] = '17%';
                vals['%PERCENT_2%'] = '72%';
            }

            if (branch === 'navy') {
                vals['%PERCENT_1%'] = '22%';
                vals['%PERCENT_2%'] = '80%';
            }

            break;
        case age <= 29:
            vals['%AGE_GROUP%'] = '26-29';
            if (branch === 'airForce') {
                vals['%PERCENT_1%'] = '24%';
                vals['%PERCENT_2%'] = '93%';
            }

            if (branch === 'army') {
                vals['%PERCENT_1%'] = '36%';
                vals['%PERCENT_2%'] = '77%';
            }

            if (branch === 'coastGuard') {
                vals['%PERCENT_1%'] = '22%';
                vals['%PERCENT_2%'] = '90%';
            }

            if (branch === 'marines') {
                vals['%PERCENT_1%'] = '31%';
                vals['%PERCENT_2%'] = '66%';
            }

            if (branch === 'navy') {
                vals['%PERCENT_1%'] = '28%';
                vals['%PERCENT_2%'] = '75%';
            }

            break;
        case age <= 34:
            vals['%AGE_GROUP%'] = '30-34';
            if (branch === 'airForce') {
                vals['%PERCENT_1%'] = '31%';
                vals['%PERCENT_2%'] = '96%';
            }

            if (branch === 'army') {
                vals['%PERCENT_1%'] = '37%';
                vals['%PERCENT_2%'] = '86%';
            }

            if (branch === 'coastGuard') {
                vals['%PERCENT_1%'] = '21%';
                vals['%PERCENT_2%'] = '75%';
            }

            if (branch === 'marines') {
                vals['%PERCENT_1%'] = '33%';
                vals['%PERCENT_2%'] = '75%';
            }

            if (branch === 'navy') {
                vals['%PERCENT_1%'] = '22%';
                vals['%PERCENT_2%'] = '77%';
            }

            break;
        case age <= 49:
            vals['%AGE_GROUP%'] = '35-49';
            if (branch === 'airForce') {
                vals['%AGE_GROUP%'] = '35-64';
                vals['%PERCENT_1%'] = '30%';
                vals['%PERCENT_2%'] = '92%';
            }

            if (branch === 'army') {
                vals['%PERCENT_1%'] = '31%';
                vals['%PERCENT_2%'] = '80%';
            }

            if (branch === 'coastGuard') {
                vals['%AGE_GROUP%'] = '35-64';
                vals['%PERCENT_1%'] = '13%';
                vals['%PERCENT_2%'] = '82%';
            }

            if (branch === 'marines') {
                vals['%AGE_GROUP%'] = '35-64';
                vals['%PERCENT_1%'] = '20%';
                vals['%PERCENT_2%'] = '75%';
            }

            if (branch === 'navy') {
                vals['%PERCENT_1%'] = '27%';
                vals['%PERCENT_2%'] = '76%';
            }

            break;
        case age <= 64:
            vals['%AGE_GROUP%'] = '50-64';
            if (branch === 'airForce') {
                vals['%AGE_GROUP%'] = '35-64';
                vals['%PERCENT_1%'] = '30%';
                vals['%PERCENT_2%'] = '92%';
            }

            if (branch === 'army') {
                vals['%PERCENT_1%'] = '27%';
                vals['%PERCENT_2%'] = '92%';
            }

            if (branch === 'coastGuard') {
                vals['%AGE_GROUP%'] = '35-64';
                vals['%PERCENT_1%'] = '13%';
                vals['%PERCENT_2%'] = '82%';
            }

            if (branch === 'marines') {
                vals['%AGE_GROUP%'] = '35-64';
                vals['%PERCENT_1%'] = '20%';
                vals['%PERCENT_2%'] = '75%';
            }

            if (branch === 'navy') {
                vals['%PERCENT_1%'] = '27%';
                vals['%PERCENT_2%'] = '94%';
            }

            break;
        default:
            return -1;
    }

    return vals;
}
