import React from 'react';
import PropTypes from 'prop-types';

import { playerWrapper } from 'cccisd-survey-common';
import HRBSRender from './render.js';

class HRBSPlayer extends React.Component {
    static propTypes = {
        isPreview: PropTypes.bool,
        pawnId: PropTypes.number,
        settings: PropTypes.object,
    };

    static defaultProps = {
        isPreview: false,
        pawnId: 0,
        settings: {},
    };

    render() {
        return <HRBSRender {...this.props} />;
    }
}

export default playerWrapper({})(HRBSPlayer);
