import React from 'react';
import PropTypes from 'prop-types';
import { playerWrapper } from 'cccisd-survey-common';
import PNFGraph from '../../../../../components/PNFGraph';

class PNFGraphFlowComponent extends React.Component {
    static propTypes = {
        field: PropTypes.object,
        settings: PropTypes.object,
        isPreview: PropTypes.bool,
        goToNextPage: PropTypes.func,
    };

    static defaultProps = {
        settings: {},
        isPreview: false,
    };

    render() {
        const { isPreview, pawnId, settings } = this.props;

        return <PNFGraph {...settings} isPreview={isPreview} pawnId={pawnId} />;
    }
}

export default playerWrapper({})(PNFGraphFlowComponent);
