import React from 'react';
import PropTypes from 'prop-types';
import { builderWrapper, FieldBlock } from 'cccisd-survey-common';
import { fields, validate, initialValues, replaceableVariables } from './settings.js';
import style from './style.css';

export class HRBSBuilder extends React.Component {
    static propTypes = {
        fields: PropTypes.object,
    };

    render() {
        const { statement, stylesJson } = this.props.fields;

        return (
            <div>
                <FieldBlock field={statement} label="Statement">
                    <div className="form-group">
                        <textarea
                            className="form-control"
                            rows={5}
                            id="statement"
                            name="statement"
                            value={statement.value}
                            onChange={statement.onChange}
                        />
                        <p className="help-block">Text may contain the following variables for replacement</p>
                        <ul className={style.replaceList}>
                            {replaceableVariables.map((v, i) => (
                                <li key={i}>
                                    <i>{v}</i>
                                </li>
                            ))}
                        </ul>
                    </div>
                </FieldBlock>
                <FieldBlock field={stylesJson} label="Styles JSON">
                    <div className="form-group">
                        <textarea
                            className="form-control"
                            rows={5}
                            id="stylesJson"
                            name="stylesJson"
                            value={stylesJson.value}
                            onChange={stylesJson.onChange}
                        />
                    </div>
                </FieldBlock>
            </div>
        );
    }
}

export default builderWrapper({
    fields,
    validate,
    initialValues,
    withTemplate: false,
    withAccommodations: false,
})(HRBSBuilder);
