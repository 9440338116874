import React from 'react';
import { DeploymentEvaluator as DefaultDeploymentEvaluator } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';
import useDemographics from '../../hooks/useDemographics';

const Fortress = window.cccisd.fortress;
const branchMap = {
    army: 'Army',
    airForce: 'Air Force',
    coastGuard: 'Coast Guard',
    marines: 'Marines',
    navy: 'Navy',
};

const DeploymentEvaluator = props => {
    if (!['learner', 'sm'].includes(Fortress.user.acting.role.handle)) {
        return <DefaultDeploymentEvaluator {...props} />;
    }

    let { isLoading, age, branch, gender, genderRaw, cpAnxietyScore, cpDepressionScore } = useDemographics({
        pawnId: Fortress.user.acting.id,
    });

    if (isLoading) {
        return <Loader loading />;
    }

    // enforce number type
    age = parseInt(age, 10);
    if (Number.isNaN(age)) {
        age = 10;
    }

    gender = parseInt(gender, 10);
    if (Number.isNaN(gender)) {
        gender = 10;
    }

    cpAnxietyScore = parseInt(cpAnxietyScore, 10);
    if (Number.isNaN(cpAnxietyScore)) {
        cpAnxietyScore = 0;
    }

    cpDepressionScore = parseInt(cpDepressionScore, 10);
    if (Number.isNaN(cpDepressionScore)) {
        cpDepressionScore = 0;
    }

    return (
        <DefaultDeploymentEvaluator
            {...props}
            flowProps={{
                variables: {
                    age,
                    branch: branchMap[branch] || branch,
                    gender,
                    genderRaw,
                    cpAnxietyScore,
                    cpDepressionScore,
                },
            }}
        />
    );
};

export default DeploymentEvaluator;
